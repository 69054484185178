<template>
  <v-row v-if="dataEvents" class="fill-height">
    <v-col cols="12" :sm="isAdmin ? 9 : 12">
      <v-sheet
        :height="
          isAdmin
            ? !$vuetify.breakpoint.xsOnly
              ? 500
              : ''
            : !$vuetify.breakpoint.xsOnly
            ? 550
            : ''
        "
        style="width: 100%; overflow: auto"
      >
        <v-calendar
          ref="calendar"
          v-model="value"
          :events="dataEvents.data"
          :interval-count="dataEvents.intervalCount"
          :first-interval="dataEvents.firstInterval"
          :interval-format="intervalFormat"
          :event-color="item => sortColor(item)"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :locale="g_language"
          :short-weekdays="false"
          interval-height="36"
          start="2020-07-27"
          min-weeks="2"
          type="week"
          interval-minutes="15"
          event-overlap-mode="column"
          style="min-width: 650px;"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @click:event="openDetailLesson"
        >
          <template #day-label-header="{ day }">
            <div v-if="day" />
          </template>
          <template #event="{ eventParsed }">
            <v-row v-if="isAdmin && eventParsed.input.flag !== 0" no-gutters>
              <v-icon
                v-if="eventParsed.input.isZoomEnable && g_include_vidcon"
                class="pl-1"
                small
              >
                mdi-video
              </v-icon>
              <v-spacer />
              <v-btn
                small
                icon
                color="white"
                @click="dialogDelete(eventParsed.input)"
              >
                <v-icon small>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-row>

            <v-row
              v-if="
                (eventParsed.input.isTeach || isStudent) &&
                  eventParsed.input.flag != 0 &&
                  eventParsed.input.isZoomEnable &&
                  g_include_vidcon &&
                  !isManageSchedule
              "
              no-gutters
            >
              <v-icon color="white" class="mx-1" small>mdi-video</v-icon>
              <v-spacer />
            </v-row>
            <div class="v-event-draggable">
              {{ eventParsed.input.name }}
            </div>
            <div class="v-event-draggable d-flex flex-column">
              <div v-if="myScheduleOnly">
                {{ $t("app.class") }} {{ eventParsed.input.class_name }}
              </div>
              <span
                v-for="teacher in eventParsed.input.teachers"
                :key="teacher.id"
              >
                - {{ teacher.name }}
              </span>
            </div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
    <v-col
      v-if="isAdmin"
      :class="$vuetify.breakpoint.xsOnly && 'mt-5'"
      cols="12"
      sm="3"
    >
      <v-card class="mx-auto" max-height="500">
        <v-toolbar class="gradient-primary" dark>
          <v-toolbar-title class="body-1">
            {{ totalLeftLesson + " " + $t("schedule.available_subjects") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-list>
          <v-list-item
            v-for="item in dataEvents.left_lesson"
            :key="item.hm"
            :disabled="loadingLesson"
            @click="insertLesson(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
              <v-list-item-subtitle
                v-for="(teacher, index) in item.teachers"
                :key="index"
              >
                - {{ teacher.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <ModalConfirm
      :close="() => (this.confirmDelete = false)"
      :loading="loadingBtnDelete"
      :isOpen="confirmDelete"
      :save="deleteLesson"
    />
    <ModalConfirm
      :close="
        () => {
          this.getSchedule(this.idClass);
          this.confirmForce = false;
        }
      "
      :loading="loadingBtnForce"
      :isOpen="confirmForce"
      :save="
        () => {
          this.forcable = true;
          this.switchEvent();
        }
      "
      :content="messageForceSchedule"
    />
    <v-dialog
      v-model="modelDetailLesson"
      persistent
      scrollable
      max-width="600px"
      transition="scale-transition"
    >
      <v-card>
        <v-card-title primary-title>
          <v-row no-gutters align="center">
            {{
              itemViewLesson.flag === 1
                ? $t("schedule.view_lesson")
                : $t("schedule.view_event")
            }}
            <v-spacer />
            <v-btn
              :disabled="loadingModalInfo"
              icon
              @click="
                () => {
                  this.tableModel = [];
                  this.classesLesson = [];
                  this.showClassTable = false;
                  this.checkRoomStatus(this.itemViewLesson.id, true);
                  this.itemViewLesson = {};
                  this.modelDetailLesson = false;
                  this.sameHours = false;
                }
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-2" />
        <v-card-text>
          <v-row>
            <v-col cols="4">
              {{
                itemViewLesson.flag === 1 ? $t("schedule.subjects") : "Event"
              }}
            </v-col>
            <v-col cols="8"> : {{ itemViewLesson.name }}</v-col>
            <v-col cols="4">{{ $t("schedule.hour") }}</v-col>
            <v-col cols="8">
              : {{ getTime(itemViewLesson.start) }} -
              {{ getTime(itemViewLesson.end) }}</v-col
            >
            <v-col v-if="itemViewLesson.flag === 1" cols="4">
              {{ $t("schedule.teacher") }}
            </v-col>
            <v-col
              v-if="itemViewLesson.flag === 1"
              cols="8"
              class="d-flex flex-row"
            >
              :
              <div class="d-flex flex-column ml-1">
                <span
                  v-for="(teacher, index) in itemViewLesson.teachers"
                  :key="index"
                >
                  {{ teacher.name }}
                </span>
              </div>
            </v-col>
            <v-col v-if="myScheduleOnly" cols="4">{{ $t("app.class") }}</v-col>
            <v-col v-if="myScheduleOnly" cols="8">
              : {{ itemViewLesson.class_name }}</v-col
            >
            <v-col
              v-if="
                itemViewLesson.isTeach && g_include_vidcon && !isManageSchedule
              "
              cols="12"
            >
              <div class="d-flex align-center justify-end mt-3">
                <!-- <v-tooltip
                  v-if="itemViewLesson.isZoomEnable"
                  bottom
                  max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                  </template>
                  <span v-html="$t('schedule.create_room_information')" />
                </v-tooltip> -->
                <!-- <v-checkbox
                  v-model="itemViewLesson.isZoomEnable"
                  :label="$t('schedule.create_video_conference')"
                  class="ml-2 mt-n1"
                  hide-details
                  dense
                  color="primary"
                  @change="createRoom(itemViewLesson)"
                />
                <v-spacer /> -->
                <v-switch
                  v-if="isZoomSelected"
                  v-model="sameHours"
                  :label="$t('schedule.same_hours')"
                  inset
                  dense
                />
              </div>
              <div v-if="isZoomSelected">
                <v-alert
                  v-if="itemViewLesson.isZoomEnable"
                  color="blue-grey lighten-3"
                  border="left"
                  dark
                  class="mb-5 d-flex justify-center"
                >
                  {{ $t("schedule.info_merge_class") }}
                </v-alert>
                <v-data-table
                  v-model="tableModel"
                  :items="classesLesson"
                  :headers="headersClass"
                  :loading="loadingClassesLesson"
                  :items-per-page="classesLesson.length"
                  item-key="id"
                  disable-pagination
                  show-select
                  hide-default-footer
                >
                  <template #item.hours="{ item }">
                    {{ item.start_time.substr(0, 5) }} -
                    {{ item.end_time.substr(0, 5) }}
                  </template>
                  <template #header.data-table-select>
                    {{ $t("app.select") }}
                  </template>
                  <template
                    #item.data-table-select="{ item, isSelected, select }"
                  >
                    <v-icon v-if="item.id === itemViewLesson.id" color="grey">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-tooltip
                      v-else-if="
                        !itemViewLesson.isZoomEnable ||
                          loadingModalInfo ||
                          loading
                      "
                      left
                      max-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" color="grey">
                          {{
                            isSelected
                              ? "mdi-checkbox-marked"
                              : "mdi-square-outline"
                          }}
                        </v-icon>
                      </template>
                      <span>{{ $t("schedule.msg_merge_class") }}</span>
                    </v-tooltip>
                    <v-simple-checkbox
                      v-else
                      :value="isSelected"
                      @input="handleSelectClass(item, isSelected, select)"
                    />
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col v-if="itemViewLesson.isTeach && !isManageSchedule" cols="12">
              <v-form ref="vidConLink">
                <v-radio-group
                  v-model="itemViewLesson.vidcon_type"
                  @change="changeRadio"
                >
                  <v-radio
                    v-if="g_include_vidcon"
                    label="Zoom Video Conference"
                    value="ZOOM"
                  />
                  <v-radio value="LINK">
                    <template #label>
                      <v-text-field
                        v-model="itemViewLesson.vidcon_link"
                        :rules="[v => !!isURL(v)]"
                        label="Link Video Conference"
                        hide-details
                        outlined
                        dense
                      />
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="ma-3 align-end">
          <v-row>
            <v-col
              v-if="
                itemViewLesson.isTeach ||
                  itemViewLesson.isZoomEnable ||
                  isManageSchedule
              "
              cols="12"
              sm="8"
            >
              <v-btn
                :disabled="!itemViewLesson.isZoomEnable"
                :dark="itemViewLesson.isZoomEnable"
                :loading="itemViewLesson.room_code ? false : loadingModalInfo"
                :block="$vuetify.breakpoint.xsOnly"
                class="px-2"
                color="primary"
                @click="gotToVidCon(itemViewLesson)"
              >
                <v-icon class="mr-2">mdi-video</v-icon>
                <span>{{ $t("schedule.start_video_conference") }}</span>
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              v-if="itemViewLesson.isTeach"
              :class="$vuetify.breakpoint.xsOnly ? 'mt-3' : ''"
              cols="12"
              sm="4"
            >
              <v-row no-gutters justify="end">
                <v-btn
                  :block="$vuetify.breakpoint.xsOnly"
                  :loading="loadingSave"
                  :disabled="loadingModalInfo"
                  :dark="!loadingModalInfo"
                  outlined
                  color="primary"
                  @click="createRoom(itemViewLesson)"
                >
                  {{ $t("app.save") }}
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="bottomSheet" max-width="700" persistent scrollable>
      <v-sheet class="text-center">
        <v-card>
          <v-card-title>
            {{ $t("schedule.update_email") }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div class="my-3">
              <v-alert type="info" icon="mdi-information-outline">
                {{ $t("schedule.info_email_dialog") }}
              </v-alert>
              <v-form ref="emailDialog" lazy-validation>
                <v-text-field
                  v-model="emailDialog"
                  :rules="[
                    v =>
                      /.+@.+\..+/.test(v) || $t('ppdb.rules.email_valid_rules')
                  ]"
                  label="Email"
                  clearable
                  outlined
                  dense
                />
              </v-form>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              :disabled="loadingDialogEmail"
              color="primary"
              class="mr-2"
              outlined
              @click="cancelUpdateEmail(itemViewLesson)"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              :disabled="loadingDialogEmail"
              class="gradient-warning"
              dark
              depressed
              @click="createRoom(itemViewLesson)"
            >
              {{ $t("app.skip") }}
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="!emailDialogValid"
              :loading="loadingDialogEmail"
              dark
              class="gradient-primary"
              depressed
              @click="updateEmailTeacher(itemViewLesson)"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </v-row>
</template>

<script>
import {
  getLessonSchedule,
  switchLessonSchedule,
  insertLessonSchedule,
  deleteLessonSchedule,
  createRoomZoom,
  updateEmail,
  checkRoomStatus,
  lessonSameSub,
  updateLessonSubMeeting,
  createVidconLink
} from "@/api/admin/academic/schedule";
import moment from "moment/moment";
moment.locale("id");
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import store from "@/store";
import { isURL } from "@/utils/validate";

export default {
  props: {
    idClass: Number,
    isInitData: Boolean,
    idSchoolYear: Number,
    myScheduleOnly: Boolean,
    isManageSchedule: Boolean,
    loading: Boolean
  },
  components: {
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  computed: {
    isZoomSelected() {
      return this.showClassTable && this.itemViewLesson.vidcon_type === "ZOOM";
    },
    ...mapGetters(["g_language", "g_user", "g_include_vidcon"]),
    initData: {
      set(newValue) {
        this.resetInitData(newValue);
      },
      get() {
        return this.isInitData;
      }
    },
    isAdmin() {
      return !this.myScheduleOnly && !this.isStudent;
    }
  },
  mounted() {
    if (this.isStudent) {
      this.getSchedule(this.g_user.student.class);
    }
    if (this.myScheduleOnly) this.getSchedule();
  },
  watch: {
    emailDialog() {
      this.emailDialogValid = this.$refs.emailDialog.validate();
    },
    idClass(newValue) {
      this.getSchedule(newValue);
    },
    idSchoolYear() {
      if (this.idClass) this.getSchedule(this.idClass);
    },
    isInitData(value) {
      if (value) {
        this.getSchedule(this.idClass);
      }
    },
    sameHours(value) {
      const item = this.itemViewLesson;
      this.getLessonSameSub(item.id, item.lesson_sub_master, value);
    }
  },
  data: () => ({
    sameHours: false,
    loadingSave: false,
    showClassTable: false,
    tableModel: [],
    messageForceSchedule: "",
    forcable: false,
    loadingBtnForce: false,
    confirmForce: false,
    emailDialogValid: false,
    emailDialog: "",
    email: store.getters.g_user.email,
    totalLeftLesson: 0,
    isStudent: store.getters.g_role_type === "STUDENT",
    confirmDelete: false,
    bottomSheet: false,
    loadingLesson: false,
    modelDetailLesson: false,
    loadingBtnDelete: false,
    headersClass: [
      {
        text: i18n.t("app.class"),
        value: "name",
        sortable: false
      },
      {
        text: i18n.t("app.hours"),
        value: "hours",
        sortable: false,
        align: "center"
      },
      {
        value: "data-table-select",
        sortable: false,
        width: 150
      }
    ],
    tableHeaders: [
      {
        text: i18n.t("app.subjects"),
        align: "center",
        value: "name",
        sortable: false,
        width: 90
      }
    ],
    flag: 0,
    switchData: {
      id: 0,
      destination: {
        date: "",
        start: "",
        end: ""
      }
    },
    value: "",
    events: [],
    dataEvents: null,
    dragEvent: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    startDragTime: null,
    isCancelDrag: false,
    loadingModalInfo: false,
    loadingDialogEmail: false,
    idDelete: 0,
    flagDelete: 0,
    itemViewLesson: {},
    classesLesson: [],
    loadingClassesLesson: false
  }),
  methods: {
    changeRadio(model) {
      if (model === "ZOOM") {
        this.itemViewLesson.vidcon_link = null;
        this.$refs.vidConLink.resetValidation();
      }
    },
    isURL: str => isURL(str),
    handleSelectClass(item, isSelected, select) {
      this.cancelMergeMeeting(item, isSelected);
      select();
    },
    async cancelMergeMeeting(item, isSelected) {
      try {
        const itemView = this.itemViewLesson;
        const response = await updateLessonSubMeeting({
          lesson_schedule: item.id,
          zoom_id: isSelected ? null : itemView.zoom_id
        });
        if (response.data.code) {
          this.getLessonSameSub(item.id, item.lesson_sub_master);
        } else this.snackBar(false, response.data.message);
      } catch (error) {
        console.error("cancelMergeMeeting(item)\n", error);
      }
    },
    async getLessonSameSub(id, isSubLesson, isSameHours) {
      this.loadingClassesLesson = true;
      try {
        const response = await lessonSameSub(
          { lesson_schedule: id },
          isSubLesson != null
        );
        if (response.data.code) {
          this.classesLesson = response.data.data;
          this.tableModel = this.classesLesson.filter(
            item =>
              item.zoom_id !== null &&
              item.zoom_id === this.itemViewLesson.zoom_id
          );
          this.showClassTable = this.classesLesson.length > 1;
          if (isSameHours) {
            this.classesLesson = this.classesLesson.filter(
              v =>
                v.start_time.substr(0, 5) ===
                  this.getTime(this.itemViewLesson.start) &&
                v.end_time.substr(0, 5) ===
                  this.getTime(this.itemViewLesson.end)
            );
          }
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("getLessonSameSub(id)\n", error);
      } finally {
        this.loadingClassesLesson = false;
      }
    },
    async checkRoomStatus(id, closeDialog) {
      if (this.itemViewLesson.vidcon_type === "ZOOM") {
        this.mainLoading(true);
        this.$Progress.start();
        const idClass = this.isStudent
          ? this.g_user.student.class
          : this.idClass;
        try {
          /**
           * Info Response
           *
           * null: Belum Pernah Ada Room Yang Dibuat
           * ACTIVE: Room Masih Aktif
           * INACTIVE: Room Sudah Tidak Aktif
           */
          const response = await checkRoomStatus({ lesson_schedule: id });
          if (response.data.code) {
            if (closeDialog) {
              this.getSchedule(idClass);
            } else {
              this.itemViewLesson.isZoomEnable =
                response.data.data === "ACTIVE";
            }
          } else this.snackBar(true, response.data.message);
        } catch (error) {
          console.error("checkRoomStatus(id)\n", error);
        } finally {
          this.mainLoading(false);
          this.$Progress.finish();
        }
      }
    },
    cancelUpdateEmail(item) {
      this.resetFormEmail();
      this.bottomSheet = false;
      item.isZoomEnable = false;
    },
    checkEmailExist(item) {
      if (this.email || !item.isZoomEnable) this.createRoom(item);
      else this.bottomSheet = true;
    },
    resetFormEmail() {
      this.$refs.emailDialog.reset();
      this.$refs.emailDialog.resetValidation();
    },
    async updateEmailTeacher(item) {
      this.loadingDialogEmail = true;
      try {
        const body = {
          email: this.emailDialog,
          person: store.getters.g_user.person
        };
        const response = await updateEmail(body);
        if (response.data.code) {
          this.bottomSheet = false;
          this.resetFormEmail();
          this.createRoom(item);
          this.snackBar(
            false,
            this.$i18n.t("schedule.msg_response.success_update_email")
          );
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        console.error("updateEmailTeacher()\n", error);
        this.loadingDialogEmail = false;
      }
      this.loadingDialogEmail = false;
    },
    openDetailLesson(item) {
      if (!this.confirmDelete) {
        const destination = this.switchData.destination;
        const data = item.event;
        if (!destination.start || !destination.end) {
          this.itemViewLesson = data;
          if (data.isTeach) {
            this.getLessonSameSub(data.id, data.lesson_sub_master);
          }
          this.modelDetailLesson = true;
          this.$nextTick(() => {
            this.$refs.vidConLink.resetValidation();
          });
          this.checkRoomStatus(data.id);
        }
      }
    },
    checkProtocol(url) {
      if (/(http(s?)):\/\//i.test(url)) {
        return url;
      } else return "https://".concat(url);
    },
    gotToVidCon(item) {
      const routeData = this.$router.resolve({
        name: "Zoom",
        query: {
          options: item.zoom_options
        }
      });
      const link = this.checkProtocol(item.vidcon_link);
      switch (item.vidcon_type) {
        case "ZOOM":
          setTimeout(() => {
            window.open(routeData.href, "_blank");
          }, 200);
          break;
        case "LINK":
          if (this.isURL(link)) {
            setTimeout(() => {
              window.open(link, "_blank");
            }, 200);
          } else {
            this.snackBar(
              true,
              this.$i18n.t("schedule.msg_response.vidcon_link_invalid")
            );
          }
          break;
        default:
          this.snackBar(true, this.$i18n.t("schedule.msg_no_room_code"));
          break;
      }
    },
    getUrlViCon(roomCode, aworOptions) {
      if (roomCode && aworOptions) {
        const link =
          this.dataEvents.awor_url_webmeet +
          roomCode +
          "?options=" +
          aworOptions;
        window.open(link, "_blank");
      } else {
        console.error("Room code or awor options not found");
        this.snackBar(true, this.$i18n.t("schedule.msg_no_room_code"));
      }
    },
    async createRoom(item) {
      this.loadingDialogEmail = true;
      this.loadingSave = true;
      try {
        if (item.vidcon_type === "ZOOM") {
          const body = {
            topic: item.name,
            schedule_id: item.id,
            included_lesson_schedules: this.tableModel.map(v => v.id)
          };
          // const body = {
          //   lesson: item.lesson,
          //   classroom: this.idClass,
          //   teacher: item.teacher,
          //   lesson_schedule: item.id
          // };
          const response = await createRoomZoom(body);
          if (response.data.code) {
            const data = response.data.data;
            if (data) {
              this.itemViewLesson.zoom_options = data.zoom_options;
            }
            this.bottomSheet = false;
            this.getSchedule(this.idClass);
          } else {
            this.snackBar(true, response.data.message);
          }
        } else {
          if (this.$refs.vidConLink.validate()) {
            const response = await createVidconLink({
              id: item.id,
              vidcon_link: item.vidcon_link
            });
            if (response.data.code) {
              this.getSchedule(this.idClass);
            } else {
              this.snackBar(
                true,
                this.$i18n.t("schedule.msg_response.failed_input_vidcon_link")
              );
            }
          } else {
            this.snackBar(
              true,
              this.$i18n.t("schedule.msg_response.vidcon_link_invalid")
            );
          }
        }
      } catch (error) {
        console.error("createRoom(item)\n", error);
      } finally {
        this.loadingDialogEmail = false;
        this.loadingSave = false;
      }
    },
    dialogDelete(item) {
      this.confirmDelete = true;
      this.idDelete = item.id;
      this.flagDelete = item.flag;
    },
    async deleteLesson() {
      this.loadingBtnDelete = true;
      const msg =
        this.flagDelete === 1
          ? this.$i18n.t("schedule.msg_response.remove_subjects")
          : this.$i18n.t("schedule.msg_response.success_remove_event");
      await deleteLessonSchedule({ id: this.idDelete })
        .then(res => {
          if (res.data.code === 1) {
            this.snackBar(false, msg);
            this.getSchedule(this.idClass);
            this.confirmDelete = false;
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          this.snackBar(true, err);
          this.loadingBtnDelete = false;
        });
      this.loadingBtnDelete = false;
    },
    async insertLesson(item) {
      if (this.confirmForce) this.loadingBtnForce = true;
      this.loadingLesson = true;
      try {
        const response = await insertLessonSchedule({
          force: this.forcable,
          class: this.idClass,
          lesson: item.lesson
        });
        if (response.data.code === 1) {
          this.snackBar(
            false,
            this.$i18n.t("schedule.msg_response.input_subjects")
          );
          this.confirmForce = false;
          this.getSchedule(this.idClass);
        } else {
          if (response.data.data) {
            this.messageForceSchedule =
              response.data.message +
              this.$i18n.t("schedule.confirm_force_schedule");
            this.confirmForce = true;
          } else {
            this.confirmForce = false;
            this.getSchedule(this.idClass);
            this.snackBar(true, response.data.message);
          }
        }
      } catch (error) {
        this.getSchedule(this.idClass);
        console.error("insertLesson(item)\n", error);
        this.forcable = false;
        this.confirmForce = false;
        this.loadingLesson = false;
        this.loadingBtnForce = false;
      }
      this.loadingLesson = false;
      this.loadingBtnForce = false;
    },
    getTime: time => moment(time).format("HH:mm"),
    sortColor(item) {
      switch (item.flag) {
        case 0:
          return "grey darken-3";
        case 1:
          if ((item.isTeach || this.isStudent) && item.flag != 0) {
            return item.isZoomEnable ? "success" : "teal lighten-1";
          } else return "primary";
        case 2:
          return "orange";
        default:
          return "primary";
      }
    },
    intervalFormat: interval => interval.time,
    resetInitData(value) {
      this.$emit("changeInitData", value);
    },
    mainLoading(value) {
      this.$emit("loading", value);
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    async switchEvent() {
      if (this.confirmForce) this.loadingBtnForce = true;
      await switchLessonSchedule({
        ...this.switchData,
        school_year: this.idSchoolYear,
        force: this.forcable
      })
        .then(res => {
          if (res.data.code === 1) {
            this.getSchedule(this.idClass);
            this.confirmForce = false;
            this.forcable = false;
            this.snackBar(
              false,
              this.$i18n.t("schedule.msg_response.drag_subjects")
            );
          } else {
            if (res.data.data) {
              this.messageForceSchedule =
                res.data.message +
                this.$i18n.t("schedule.confirm_force_schedule");
              this.confirmForce = true;
            } else {
              this.snackBar(true, res.data.message);
              this.getSchedule(this.idClass);
              this.confirmForce = false;
            }
          }
          this.loadingBtnForce = false;
        })
        .catch(err => {
          this.getSchedule(this.idClass);
          console.error("switchEvent()\n", err);
          this.forcable = false;
          this.confirmForce = false;
          this.loadingBtnForce = false;
        });
      if (!this.confirmForce) {
        // reset switchData
        this.switchData = {
          id: 0,
          destination: {
            date: "",
            start: "",
            end: ""
          }
        };
      }
    },
    getSchedule(idClass) {
      this.mainLoading(true);
      this.$Progress.start();
      this.loadingModalInfo = true;
      const body = idClass
        ? {
            class: idClass,
            school_year: this.idSchoolYear
          }
        : { my_schedule_only: this.myScheduleOnly };
      getLessonSchedule(body)
        .then(res => {
          if (res.data.code) {
            res.data.data.data.map(item => {
              item.isTeach = item.is_i_teach?.toUpperCase() === "TRUE";
              item.isRoomEnable = item.is_room_enable?.toUpperCase() === "TRUE";
              item.isZoomEnable = item.vidcon_type !== null;
            });
            this.dataEvents = res.data.data;
            this.totalLeftLesson = this.dataEvents.left_lesson.length;
            this.dataEvents.data.map(d => {
              d.startTime = d.start;
              d.endTime = d.end;
              d.start = moment(d.start).valueOf();
              d.end = moment(d.end).valueOf();
            });
            this.dataEvents.firstInterval *= 4;
            this.dataEvents.intervalCount *= 4;
            // update data dialog detail
            if (Object.keys(this.itemViewLesson).length !== 0) {
              this.itemViewLesson = this.dataEvents.data.find(
                item => item.id === this.itemViewLesson.id
              );
            }
          } else {
            this.snackBar(true, res.data.message);
          }
        })
        .catch(err => {
          console.error("getSchedule(idClass)\n", err);
          this.snackBar(true, err);
        })
        .finally(() => {
          this.mainLoading(false);
          this.$Progress.finish();
          this.initData = false;
          this.loadingModalInfo = false;
        });
    },
    startDrag({ event, timed }) {
      if (this.isAdmin) {
        this.startDragTime = moment(event.start).valueOf();
        this.switchData.id = event.id;
        this.flag = event.flag;
        if (event && timed && event.flag != 0 && event.flag != 2) {
          this.dragEvent = event;
          this.dragTime = null;
          this.extendOriginal = null;
        }
      }
    },
    startTime(tms) {
      if (this.isAdmin) {
        const mouse = this.toTime(tms);

        if (
          this.dragEvent &&
          this.dragTime === null &&
          this.flag != 0 &&
          this.flag != 2
        ) {
          const start = this.dragEvent.start;

          this.dragTime = mouse - start;
        }
      }
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;

        const startTimestamp = moment
          .unix(moment(start).unix())
          .format("YYYY-MM-DD H:mm:ss");

        const endTimestamp = moment
          .unix(moment(end).unix())
          .format("YYYY-MM-DD H:mm:ss");

        this.switchData.destination.start = startTimestamp;
        this.switchData.destination.end = endTimestamp;
        // check cancel drag
        // this.isCancelDrag = this.startDragTime === start;
        this.isCancelDrag = false;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag(event) {
      this.switchData.destination.date = event.date;
      if (
        !this.isCancelDrag &&
        this.flag != 0 &&
        this.flag != 2 &&
        this.dragEvent &&
        this.switchData.destination.start
      ) {
        this.switchEvent();
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    roundTime(time, down = true) {
      // const roundTo = 60; // minutes
      const roundDownTime = this.dataEvents.roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    }
  }
};
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}
.v-list {
  height: 480px;
  overflow-y: auto;
}
</style>
