var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataEvents)?_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","sm":_vm.isAdmin ? 9 : 12}},[_c('v-sheet',{staticStyle:{"width":"100%","overflow":"auto"},attrs:{"height":_vm.isAdmin
          ? !_vm.$vuetify.breakpoint.xsOnly
            ? 500
            : ''
          : !_vm.$vuetify.breakpoint.xsOnly
          ? 550
          : ''}},[_c('v-calendar',{ref:"calendar",staticStyle:{"min-width":"650px"},attrs:{"events":_vm.dataEvents.data,"interval-count":_vm.dataEvents.intervalCount,"first-interval":_vm.dataEvents.firstInterval,"interval-format":_vm.intervalFormat,"event-color":item => _vm.sortColor(item),"weekdays":[1, 2, 3, 4, 5, 6, 0],"locale":_vm.g_language,"short-weekdays":false,"interval-height":"36","start":"2020-07-27","min-weeks":"2","type":"week","interval-minutes":"15","event-overlap-mode":"column"},on:{"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag,"click:event":_vm.openDetailLesson},scopedSlots:_vm._u([{key:"day-label-header",fn:function({ day }){return [(day)?_c('div'):_vm._e()]}},{key:"event",fn:function({ eventParsed }){return [(_vm.isAdmin && eventParsed.input.flag !== 0)?_c('v-row',{attrs:{"no-gutters":""}},[(eventParsed.input.isZoomEnable && _vm.g_include_vidcon)?_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v(" mdi-video ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":"","color":"white"},on:{"click":function($event){return _vm.dialogDelete(eventParsed.input)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1):_vm._e(),(
              (eventParsed.input.isTeach || _vm.isStudent) &&
                eventParsed.input.flag != 0 &&
                eventParsed.input.isZoomEnable &&
                _vm.g_include_vidcon &&
                !_vm.isManageSchedule
            )?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mx-1",attrs:{"color":"white","small":""}},[_vm._v("mdi-video")]),_c('v-spacer')],1):_vm._e(),_c('div',{staticClass:"v-event-draggable"},[_vm._v(" "+_vm._s(eventParsed.input.name)+" ")]),_c('div',{staticClass:"v-event-draggable d-flex flex-column"},[(_vm.myScheduleOnly)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("app.class"))+" "+_vm._s(eventParsed.input.class_name)+" ")]):_vm._e(),_vm._l((eventParsed.input.teachers),function(teacher){return _c('span',{key:teacher.id},[_vm._v(" - "+_vm._s(teacher.name)+" ")])})],2)]}}],null,false,3170494950),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),(_vm.isAdmin)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly && 'mt-5',attrs:{"cols":"12","sm":"3"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-height":"500"}},[_c('v-toolbar',{staticClass:"gradient-primary",attrs:{"dark":""}},[_c('v-toolbar-title',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.totalLeftLesson + " " + _vm.$t("schedule.available_subjects"))+" ")])],1),_c('v-list',_vm._l((_vm.dataEvents.left_lesson),function(item){return _c('v-list-item',{key:item.hm,attrs:{"disabled":_vm.loadingLesson},on:{"click":function($event){return _vm.insertLesson(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_vm._l((item.teachers),function(teacher,index){return _c('v-list-item-subtitle',{key:index},[_vm._v(" - "+_vm._s(teacher.name)+" ")])})],2)],1)}),1)],1)],1):_vm._e(),_c('ModalConfirm',{attrs:{"close":() => (this.confirmDelete = false),"loading":_vm.loadingBtnDelete,"isOpen":_vm.confirmDelete,"save":_vm.deleteLesson}}),_c('ModalConfirm',{attrs:{"close":() => {
        this.getSchedule(this.idClass);
        this.confirmForce = false;
      },"loading":_vm.loadingBtnForce,"isOpen":_vm.confirmForce,"save":() => {
        this.forcable = true;
        this.switchEvent();
      },"content":_vm.messageForceSchedule}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"600px","transition":"scale-transition"},model:{value:(_vm.modelDetailLesson),callback:function ($$v) {_vm.modelDetailLesson=$$v},expression:"modelDetailLesson"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(_vm.itemViewLesson.flag === 1 ? _vm.$t("schedule.view_lesson") : _vm.$t("schedule.view_event"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loadingModalInfo,"icon":""},on:{"click":() => {
                this.tableModel = [];
                this.classesLesson = [];
                this.showClassTable = false;
                this.checkRoomStatus(this.itemViewLesson.id, true);
                this.itemViewLesson = {};
                this.modelDetailLesson = false;
                this.sameHours = false;
              }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-divider',{staticClass:"mb-2"}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.itemViewLesson.flag === 1 ? _vm.$t("schedule.subjects") : "Event")+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" : "+_vm._s(_vm.itemViewLesson.name))]),_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t("schedule.hour")))]),_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" : "+_vm._s(_vm.getTime(_vm.itemViewLesson.start))+" - "+_vm._s(_vm.getTime(_vm.itemViewLesson.end)))]),(_vm.itemViewLesson.flag === 1)?_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("schedule.teacher"))+" ")]):_vm._e(),(_vm.itemViewLesson.flag === 1)?_c('v-col',{staticClass:"d-flex flex-row",attrs:{"cols":"8"}},[_vm._v(" : "),_c('div',{staticClass:"d-flex flex-column ml-1"},_vm._l((_vm.itemViewLesson.teachers),function(teacher,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(teacher.name)+" ")])}),0)]):_vm._e(),(_vm.myScheduleOnly)?_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t("app.class")))]):_vm._e(),(_vm.myScheduleOnly)?_c('v-col',{attrs:{"cols":"8"}},[_vm._v(" : "+_vm._s(_vm.itemViewLesson.class_name))]):_vm._e(),(
              _vm.itemViewLesson.isTeach && _vm.g_include_vidcon && !_vm.isManageSchedule
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-center justify-end mt-3"},[(_vm.isZoomSelected)?_c('v-switch',{attrs:{"label":_vm.$t('schedule.same_hours'),"inset":"","dense":""},model:{value:(_vm.sameHours),callback:function ($$v) {_vm.sameHours=$$v},expression:"sameHours"}}):_vm._e()],1),(_vm.isZoomSelected)?_c('div',[(_vm.itemViewLesson.isZoomEnable)?_c('v-alert',{staticClass:"mb-5 d-flex justify-center",attrs:{"color":"blue-grey lighten-3","border":"left","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("schedule.info_merge_class"))+" ")]):_vm._e(),_c('v-data-table',{attrs:{"items":_vm.classesLesson,"headers":_vm.headersClass,"loading":_vm.loadingClassesLesson,"items-per-page":_vm.classesLesson.length,"item-key":"id","disable-pagination":"","show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.hours",fn:function({ item }){return [_vm._v(" "+_vm._s(item.start_time.substr(0, 5))+" - "+_vm._s(item.end_time.substr(0, 5))+" ")]}},{key:"header.data-table-select",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("app.select"))+" ")]},proxy:true},{key:"item.data-table-select",fn:function({ item, isSelected, select }){return [(item.id === _vm.itemViewLesson.id)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-checkbox-marked ")]):(
                      !_vm.itemViewLesson.isZoomEnable ||
                        _vm.loadingModalInfo ||
                        _vm.loading
                    )?_c('v-tooltip',{attrs:{"left":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(isSelected ? "mdi-checkbox-marked" : "mdi-square-outline")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("schedule.msg_merge_class")))])]):_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"input":function($event){return _vm.handleSelectClass(item, isSelected, select)}}})]}}],null,false,4241730933),model:{value:(_vm.tableModel),callback:function ($$v) {_vm.tableModel=$$v},expression:"tableModel"}})],1):_vm._e()]):_vm._e(),(_vm.itemViewLesson.isTeach && !_vm.isManageSchedule)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"vidConLink"},[_c('v-radio-group',{on:{"change":_vm.changeRadio},model:{value:(_vm.itemViewLesson.vidcon_type),callback:function ($$v) {_vm.$set(_vm.itemViewLesson, "vidcon_type", $$v)},expression:"itemViewLesson.vidcon_type"}},[(_vm.g_include_vidcon)?_c('v-radio',{attrs:{"label":"Zoom Video Conference","value":"ZOOM"}}):_vm._e(),_c('v-radio',{attrs:{"value":"LINK"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('v-text-field',{attrs:{"rules":[v => !!_vm.isURL(v)],"label":"Link Video Conference","hide-details":"","outlined":"","dense":""},model:{value:(_vm.itemViewLesson.vidcon_link),callback:function ($$v) {_vm.$set(_vm.itemViewLesson, "vidcon_link", $$v)},expression:"itemViewLesson.vidcon_link"}})]},proxy:true}],null,false,3711956920)})],1)],1)],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"ma-3 align-end"},[_c('v-row',[(
              _vm.itemViewLesson.isTeach ||
                _vm.itemViewLesson.isZoomEnable ||
                _vm.isManageSchedule
            )?_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-btn',{staticClass:"px-2",attrs:{"disabled":!_vm.itemViewLesson.isZoomEnable,"dark":_vm.itemViewLesson.isZoomEnable,"loading":_vm.itemViewLesson.room_code ? false : _vm.loadingModalInfo,"block":_vm.$vuetify.breakpoint.xsOnly,"color":"primary"},on:{"click":function($event){return _vm.gotToVidCon(_vm.itemViewLesson)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-video")]),_c('span',[_vm._v(_vm._s(_vm.$t("schedule.start_video_conference")))])],1)],1):_vm._e(),_c('v-spacer'),(_vm.itemViewLesson.isTeach)?_c('v-col',{class:_vm.$vuetify.breakpoint.xsOnly ? 'mt-3' : '',attrs:{"cols":"12","sm":"4"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.xsOnly,"loading":_vm.loadingSave,"disabled":_vm.loadingModalInfo,"dark":!_vm.loadingModalInfo,"outlined":"","color":"primary"},on:{"click":function($event){return _vm.createRoom(_vm.itemViewLesson)}}},[_vm._v(" "+_vm._s(_vm.$t("app.save"))+" ")])],1)],1):_vm._e()],1)],1)],1)],1),_c('v-bottom-sheet',{attrs:{"max-width":"700","persistent":"","scrollable":""},model:{value:(_vm.bottomSheet),callback:function ($$v) {_vm.bottomSheet=$$v},expression:"bottomSheet"}},[_c('v-sheet',{staticClass:"text-center"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("schedule.update_email"))+" ")]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"my-3"},[_c('v-alert',{attrs:{"type":"info","icon":"mdi-information-outline"}},[_vm._v(" "+_vm._s(_vm.$t("schedule.info_email_dialog"))+" ")]),_c('v-form',{ref:"emailDialog",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"rules":[
                  v =>
                    /.+@.+\..+/.test(v) || _vm.$t('ppdb.rules.email_valid_rules')
                ],"label":"Email","clearable":"","outlined":"","dense":""},model:{value:(_vm.emailDialog),callback:function ($$v) {_vm.emailDialog=$$v},expression:"emailDialog"}})],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":_vm.loadingDialogEmail,"color":"primary","outlined":""},on:{"click":function($event){return _vm.cancelUpdateEmail(_vm.itemViewLesson)}}},[_vm._v(" "+_vm._s(_vm.$t("app.cancel"))+" ")]),_c('v-btn',{staticClass:"gradient-warning",attrs:{"disabled":_vm.loadingDialogEmail,"dark":"","depressed":""},on:{"click":function($event){return _vm.createRoom(_vm.itemViewLesson)}}},[_vm._v(" "+_vm._s(_vm.$t("app.skip"))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"gradient-primary",attrs:{"disabled":!_vm.emailDialogValid,"loading":_vm.loadingDialogEmail,"dark":"","depressed":""},on:{"click":function($event){return _vm.updateEmailTeacher(_vm.itemViewLesson)}}},[_vm._v(" "+_vm._s(_vm.$t("app.save"))+" ")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }